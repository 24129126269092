import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import ip from './modules/ip'
import createPer from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
  plugins: [createPer()],  //持久存储数据
  modules: {
    ip
  },
  getters
})
